<template>
  <div class="commercial">
    <headert></headert>
    <div class="top-banner">
      <img src="../../assets/image/banner/concept.png" alt/>
    </div>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb'/>
    <el-row class="comContentBg  " type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class=" padding-tb70">
          <div class="WJgroup-titleBox flex justify_between">
            <p class="WJgroup-title">{{ $t('joinUs.title') }}</p>
            <div class="searchBox hidden-xs">
              <el-input placeholder="请输入内容" v-model="keywords" prefix-icon='el-icon-search' class="input-with-select">
                <el-button slot="append" @click="getData"
                           style="background-color:#D5AF85 ;border: #D5AF85;border-radius: 0 2px 2px 0;color: #ffffff;height: 100%;">
                  {{ $t('joinUs.search') }}
                </el-button>
              </el-input>
            </div>
          </div>
          <div class="comContentRi padding-tb70">
            <div class="joinUs-emailBox">
              <img class="joinUs-emailimg" src="../../assets/image/personnel/email.png" alt="">
              <p>{{companyIdea}}</p>
<!--              <p> {{ $t('joinUs.joinEmail') }} <span id=""> hr_wbei@weigagroup.cn</span></p>-->
<!--              <p>{{ $t('joinUs.joinEmailTips') }}</p>-->
<!--              <p>{{ $t('joinUs.joinPhone') }} <span id=""> 027-87878055</span></p>-->
            </div>
            <div class="joinUs-class">
              <!--              类型 -->
              <div class="flex">
                <p style="min-width: 70px;line-height: 28px;font-size: 14px;margin-bottom: 5px;">
                  {{ $t('joinUs.companyType') }}
                </p>
                <ul class="flex" style="flex-wrap: wrap;">
                  <li v-for="(item,index) in companyTypeList" :key="index"
                      :class="item.id==companyTypeId?'classActive':''" @click="choseType(item)">{{ item.typename }}
                  </li>
                </ul>
              </div>
              <!--         公司     -->
<!--              <div class="flex align-start">-->
<!--                <p style="min-width: 70px;line-height: 28px;font-size: 14px;margin-bottom: 5px;">-->
<!--                  {{ $t('joinUs.companyName') }}-->
<!--                </p>-->
<!--                <ul class="flex" style="flex-wrap: wrap;">-->
<!--                  <li :class="-1==companyIndex?'classActive':''" @click="choseCompany(-1)">{{ $t('joinUs.all') }}</li>-->
<!--                  <li v-for="(item,index) in companyList" :key="index" :class="index==companyIndex?'classActive':''"-->
<!--                      @click="choseCompany(index)">{{ item.typename }}-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
<!--            修改后 申请公司-->
            <div class="joinUs-content">
              <ul class="joinUs-contentul">
                <li v-for="(item,index) in currentList" :key="index" class="joinUs-contentli flex justify_between">
                  <div class="joinUs-contentliTitle">
                    <h3>{{ item.title }}</h3>
                    <div class="joinUs-conCondition flex">
                      <p>{{ $t('joinUs.phone') }} {{ item.phone }} </p>
                    </div>
                  </div>
                  <div class="joinUs-btn" @click="joinUsTo(item)">
                    {{ $t('joinUs.apply') }}
                  </div>

                </li>

              </ul>
            </div>

          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="newMedia  " type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">

        <div class="">
          <h2>{{ $t('joinUs.Media') }}</h2>
          <div class="contact_ConOnebBox">
            <div class="WJgroupHonor-leftIcon WJgroupHonor-Icon" @mousemove="leftArrowShow" @click="moveRight">
              <i class="el-icon-arrow-left"></i>
            </div>
            <div class="WJgroupHonor-rightIcon WJgroupHonor-Icon" @mousemove="rightArrowShow" @click="moveLeft">
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="contact_ConOneb" ref="atlasulBox">
              <ul :style="{'width':ulwidth+'px','left':leftMove+'px'}">
                <li v-for="(item,index ) in codeList" :key="index">
                  <img :src="item.litpic" alt="">
                  <p>{{ item.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <footerb></footerb>


    <el-dialog title="" :visible.sync="dialogVisible" width="450px">
      <div slot="title" class="dialogTitle">
        {{ $t('joinUs.apply') }}
      </div>
      <el-form :model="formInline" ref="formInline" :rules='rules' class="demo-form-inline" label-position="left"
               label-width="110px">
        <el-form-item :label="$t('joinUs.formName')" prop="name">
          <el-input v-model="formInline.name" :placeholder="$t('joinUs.formNameT')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('joinUs.formPhone')" prop="phone">
          <el-input v-model="formInline.phone" :placeholder="$t('joinUs.formPhoneT')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('joinUs.formPost')" prop="post">
          <el-input v-model="formInline.post" :placeholder="$t('joinUs.formPostT')"></el-input>
        </el-form-item>
        <div class="fileBox  "
             style=" align-items: center;justify-content: center;display: flex;flex-direction: column;">
          <p class="tips">{{ $t('joinUs.annex') }}</p>
          <el-upload :action="baseurl+'?m=api&c=job&a=upload'" :multiple="false" accept="doc,docx,pdf" :limit="1"
                     :on-success="onSuccess">
            <el-button size="small"
                       style="background-color: #D5AF85;border: 0;color: #fff;border-radius: 0;">
              {{ $t('joinUs.annextIip') }}
            </el-button>
          </el-upload>
        </div>
      </el-form>
      <span slot="footer" class="flex justify_between">
        <el-button style="width: 49%;background-color: #D8D8D8;border: 0;color: #fff;border-radius: 0;"
                   @click="dialogVisible = false">{{ $t('joinUs.btnC') }}</el-button>
        <el-button style="width: 49%;background-color: #D5AF85;border: 0;color: #fff;border-radius: 0;"
                   @click="commitForm">{{ $t('joinUs.btnS') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "WJgroupIntroduce",
  components: {},
  data() {
    return {
      menuActiveIndex: '/personnelJoinUs',
      menu: {
        name: this.$t('menu.recruit'),
        path: '/personneltalentConcept',
        list: [{
          name: this.$t('menu.talented'),
          path: '/personneltalentConcept',
        }, {
          name: this.$t('menu.school'),
          path: '/personnelCadreSchool',
        }, {
          name: this.$t('menu.joinUs'),
          path: '/personnelJoinUs',
        },]
      },
      breadcrumb: [{
        name: this.$t('menu.recruit'),
        path: '/personnel',
      }, {
        name: this.$t('menu.joinUs'),
        path: '/personnelJoinUs',
      }],
      One: 0,
      input3: '',
      liBoxwidth: 180,
      ulwidth: null,
      leftMove: 0,
      lArrowShow: false,
      rArrowShow: false,
      codeList: [],
      Tindex: 1,


      typeid: 105,
      keywords: null,
      jobList: [],
      companyTypeList: [],
      companyTypeId: null,
      companyIdea:'',
      companyIndex: -1,
      dialogVisible: false,
      formInline: {
        name: '',
        phone: ''
      },
      fileList: [],
      rules: {
        name: [{
          required: true,
          message: this.$t('joinUs.formNameT'),
          trigger: 'blur'
        },],
        phone: [{
          required: true,
          message: this.$t('joinUs.formPhoneT'),
          trigger: 'blur'
        },],
        post: [{
          required: true,
          message: this.$t('joinUs.formPostT'),
          trigger: 'blur'
        },],
      },
      baseurl: process.env.NODE_ENV == 'development' ? '/api' : '/weijiaApi'
    };
  },
  computed: {
    lang() {
      return window.localStorage.getItem('language')
    },
    companyList() {
      let companyList = [];
      if (this.$store.state.article.channelList && this.$store.state.article.channelList.length > 0) {
        companyList = this.$store.state.article.channelList.filter(item => {
          return item.parent_id == this.companyTypeId;
        })
      }
      console.log(this.$utils.translate(companyList))
      return this.$utils.translate(companyList);
    },
    currentList() {
      if (this.companyIndex > -1) {
        let company = this.companyList[this.companyIndex];
        let current = this.jobList.filter(item => {
          return item.typeid == company.id;
        })
        return current;
      } else {
        return this.jobList
      }

    }
  },
  created() {
    this.getTypeList();
  },
  mounted() {

  },
  methods: {
    onSuccess(response, file, fileLis) {
      if (response.errcode == 0) {
        this.formInline.file = response.img_url;
      } else {
        this.$message.error(response.errmsg ? response.errmsg : this.$t('joinUs.annexbTN'));
        this.$refs.upload.clearFiles();
      }
    },
    commitForm() {
      console.log(1)
      this.$refs["formInline"].validate((valid) => {
        if (valid) {
          let api = '?m=api&c=job&a=apply';

          this.$request.post(api, this.formInline).then(res => {

            this.$message({
              message: this.$t('joinUs.success'),
              type: 'success'
            });

            this.formInline.name = '';
            this.formInline.phone = ''
            this.formInline.file = '';

            // this.$refs.formInline.clearFiles();
            this.dialogVisible = false
          })

        } else {
          // console.log('error submit!!');
          return false;
        }
      });

    },

    joinUsTo(item) {
      this.formInline = {
        name: '',
        phone: '',
        job_id: item.aid
      };
      console.log(this.formInline)
      this.dialogVisible = true

    },
    fixWidth() {
      this.$nextTick(() => {
        let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
        this.ulwidth = (this.liBoxwidth * this.codeList.length)
        if (this.ulwidth < ulBoxwidth) {
          this.ulwidth = ulBoxwidth
        }
      })
    },
    getTypeList() {
      this.$init.getChannelList(channelList => {
        let typeList = channelList.filter(item => {
          return item.parent_id == this.typeid;
        })
        this.companyTypeList = typeList;
        console.log(this.companyTypeList)
        this.companyTypeId = this.companyTypeList[0].id;
        this.companyIdea= this.companyTypeList[0].idea;
        this.getData();
      })
    },
    getData() {
      let api = '?m=api&c=v1.Api&a=index&_ajax=1';
      let condition =
        `ekey=1&typeid=${this.companyTypeId}&limit=100&addfields=years,number,place,duty,qualifications,phone,title_en`;
      if (this.keywords) {
        condition = condition + '&keywords=' + this.keywords
      }
      this.jobList = [];
      this.$request.post(api, {
        apiArclist_1: condition,
        apiArclist_2: `ekey=2&typeid=118&limit=100&addfields=title_en,litpic_en`,
      }).then(res => {
        this.jobList = this.$utils.translate(res.apiArclist[1].data)
        this.codeList = this.$utils.translate(res.apiArclist[2].data)
        this.fixWidth();
      })
    },
    choseCompany(e) {
      this.companyIndex = e
    },
    choseType(item) {
      if (this.companyTypeId == item.id) {
        return;
      }
      this.companyTypeId = item.id
      this.companyIdea= item.idea;
      this.getData();
    },
    leftArrowShow() {
      this.lArrowShow = false
      this.rArrowShow = true
    },
    rightArrowShow() {
      this.rArrowShow = false
      this.lArrowShow = true
    },
    moveLeft() {
      let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
      let moveWith = ulBoxwidth - this.ulwidth;
      if (this.leftMove > moveWith) {
        let Move = Math.abs(moveWith - this.leftMove)
        if (Move < this.liBoxwidth) {
          this.leftMove = this.leftMove - Move
        } else {
          this.leftMove = this.leftMove - this.liBoxwidth
        }
      }
    },
    moveRight() {

      let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
      let moveWith = ulBoxwidth - this.ulwidth;
      let moveOne = this.ulwidth / (this.codeList.length - 1)

      if (this.leftMove <= 0) {
        let Move = Math.abs(moveWith - this.leftMove)

        if (Move > this.liBoxwidth) {
          this.leftMove = 0
        } else {
          this.leftMove = this.leftMove + this.liBoxwidth
        }
      }
    },
    openOne(num) {
      this.One = num
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    }
  }
};
</script>
<style lang="less" scoped>
.fileBox {
  width: 100%;
  height: 112px;
  border: 1px solid #EAE8E8;
}

.tips {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #979797;
  margin-bottom: 10px;

}

.comContentBg {
  background: #FCFAFA;
}

.comContentRi {

  padding-left: 5%;


  .house {
    .num {
      font-size: 93px;
      font-family: Impact;
      font-weight: 400;
      color: #af8e68;
    }

    .numRe {
      margin-left: 30px;
    }

    .com {
      display: flex;

      .qualityHouse {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1f2d3a;
        line-height: 40px;

      }

      .eng {
        height: 21px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #aaaaaa;
        margin-left: 11px;
      }
    }

    p {

      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1F2D3A;
      line-height: 24px;
      margin-top: 10px;

    }
  }
}

.personnel_ul {
  width: 100%;

}

.personnel_ul li {
  margin: 20px 0;
  padding: 5px;
  background: #FFFFFF;
  border: 1px solid #F6EFE5;
}

.personnel_ul .personnel_ul_img {
  width: 320px;
  height: 150px;
}

.personnel_ul .personnel_ul_img img {
  width: 100%;
  height: 100%;
}

.personnel_ul .personnel_ul_content {
  flex: 1;
  margin-left: 40px;
}

.personnel_ul .personnel_ul_content h2 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #202C38;
  line-height: 40px;
  height: 40px;
}

.personnel_ul .personnel_ul_content p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6B7B88;
  line-height: 24px;
  height: 72px;
  width: 100%;
}

.strategy-content {
}

.strategy-content .strategy-conOne {
  width: 100%;

}

.strategy-conOimg {
  width: 100%;
  height: 350px;
  position: relative;
}

.strategy-conOnefont {
  position: absolute;
  top: 248px;
  left: 10%;
  background-color: #fff;
  width: 80%;
}

.strategy-conOnefont h3 {
  width: 80%;
  margin: 30px auto 10px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #202C38;
  line-height: 30px;
}

.strategy-conOnefont p {
  width: 80%;
  margin: 0px auto 30px;

  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6B7B88;
  line-height: 24px;
  height: 120px;
  overflow: hidden;
}

::v-deep .el-carousel__indicator.is-active button {
  background-color: #AF8E68;
}

.searchBox {
  width: 30%;
}

.joinUs-topbox {
  justify-content: space-between;
}

::v-deep .el-input-group__append,
.el-input-group__prepend {
  background-color: #d5af85;
}

.joinUs-emailBox {
  margin-top: 40px;
  background: #FAF6F3;
  border: 1px solid #FFE4C6;
  padding: 25px 30px;
  position: relative;
  white-space: pre-line;
}

.joinUs-emailBox p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1F2D3A;
  line-height: 30px;
  max-width: 80%;
}

.joinUs-emailBox p span {
  color: #AF8E68;
  font-weight: bold;
}

.joinUs-emailimg {
  position: absolute;
  //width: 104px;
  height: calc(100% - 40px ) ;
  width: auto;
  top: 19px;
  right: 50px;
}

.joinUs-content {
}

.joinUs-contentul {
}

.joinUs-contentli {
  margin-top: 30px;
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
}

.joinUs-contentliActive {
  background-color: #F4F4F4;;

  h3 {
    color: #AF8E68 !important;
  }
}

.joinUs-contentliTitle {
  padding: 20px 40px 7px;
  cursor: pointer;
}

.joinUs-contentliTitle h3 {

  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1F2D3A;
  line-height: 20px;
}

.joinUs-contentliTitle .joinUs-conCondition {
  padding: 15px 0;
}

.joinUs-contentliTitle .joinUs-conCondition p {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #262850;
  line-height: 16px;
  border-right: 2px solid #DCDFE3;
  padding-right: 20px;
  margin-left: 20px;
}

.joinUs-contentliTitle .joinUs-conCondition p:last-child {
  border: 0
}

.joinUs-contentliTitle .joinUs-conCondition p:first-child {
  margin-left: 0
}

.joinUs-btn {
  width: 100px;
  height: 38px;
  background: #D5B88E;
  border-radius: 2px;
  margin-right: 45px;
  // font-family: 'OPPOSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
}

.joinUs-btn:hover {
  background-color: #dfa05a;
  box-shadow: 5px 6px 10px rgba(213, 175, 133, 0.79);
}

.joinUs-class {
  padding: 22px 22px 0;
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  margin-top: 56px;

}

.joinUs-class div {
  margin-bottom: 25px;

}

.joinUs-class ul li {
  // font-family: 'OPPOSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #1F2D3A;
  padding: 0 10px;
  height: 28px;
  // background: #D5B88E;
  border-radius: 2px;
  margin: 0 5px 5px;
  cursor: pointer;
  transition: 0.5s;
}

.classActive {
  background: #D5B88E;
  color: #fff !important;
}

.newMedia {
  padding: 40px 0 100px;
}

.newMedia h2 {
  margin: 20px 0 24px;
  font-size: 32px;
  // font-family: OPPOSans-B-, OPPOSans-B;
  font-weight: normal;
  color: #333;
  line-height: 44px;
  letter-spacing: 2px;
}

.newMedia ul {
  display: flex;
  // flex-wrap: wrap;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: 0.5s;
}

.newMedia ul li {
  text-align: center;
  width: 160px;
  padding: 0 10px;
}

.newMedia ul li img {
  width: 160px;
  height: 160px;
}

.newMedia ul li p {
  font-size: 14px;
  // font-family: OPPOSans-R-, OPPOSans-R;
  font-weight: normal;
  color: #1F2D39;
  line-height: 20px;
  margin-top: 10px;
}


.WJgroupHonor-Icon {
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // border: 2px solid #b08e68;
  background-color: #efebe7;
  border-radius: 50%;
  position: absolute;
  top: 98px;
  cursor: pointer;
  transition: 1s;
}

.WJgroupHonor-Icon:hover {
  background-color: #b08e68;

}

.WJgroupHonor-Icon:hover i {
  color: #ffffff !important;

}

.WJgroupHonor-Icon i {
  color: #b08e68;
  transition: 1s;
  font-size: 30px
}

.WJgroupHonor-leftIcon {
  left: 10px;
}

.WJgroupHonor-rightIcon {
  right: 10px;
}

.contact_ConOnebBox {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 220px;
  position: relative;
  background-color: #FFFFFF;
  padding: 40px 20px;
}


.contact_ConOneb {
  // padding: 30px 50px;
  color: #1F2D39;
  width: auto;
  margin-left: 50px;
  margin-right: 50px;
  height: 100%;
  overflow: hidden;
  overflow-x: scroll;
  position: relative;
}

.contact_ConOneb::-webkit-scrollbar {
  display: none;
}


.contact_ConOne h1 {
  font-size: 24px;
  // font-family: OPPOSans-B-, OPPOSans-B;
  font-weight: normal;
  color: #1F2D39;
  line-height: 26px;
  letter-spacing: 2px;

}

.contact_ConOneMsg {
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  transition: 1s;
  position: relative;
  padding-left: 40px;

  &:hover {
    .contact_ConOneicon {
      top: 0 !important;
    }

    .contact_Conicon {
      top: 0 !important;
    }
  }
}

.contact_Conicon {
  position: absolute;
  top: 28%;
  left: 0;
}

.contact_ConOneMsg i {
  font-size: 26px;
  margin-right: 10px;
  transition: 0.3s;
}

.contact_ConOneMsg .contact_ConOneicon {
  position: absolute;
  top: 12%;
  left: 0;
}

.contact_ConOneMsg p {
  cursor: default;
  font-size: 16px;
  // font-family: OPPOSans-R-, OPPOSans-R;
  font-weight: normal;
  color: #1F2D39;
  line-height: 32px;
  letter-spacing: 2px;
}

.dialogTitle {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  padding-left: 20px;
  position: relative;
}

.dialogTitle::before {
  content: "";
  width: 3px;
  height: 18px;
  background: #D5AF85;
  position: absolute;
  top: 2px;
  left: 0;
}

@media screen and (min-width:320px) and (max-width:767px) {
  .joinUs-emailBox{
    p{
      font-size: 34px;
      line-height: 50px;
    }
  }
  .comContentRi{
    padding-left: 0!important;
  }
  .joinUs-class ul li{
    font-size: 34px;
    line-height: 52px;
    height: 52px;
    padding:0 20px;
  }
  .joinUs-contentliTitle{
    h3{
      font-size: 36px;
      font-weight: 500;
      line-height: 54px;
    }
    .joinUs-conCondition{
      p{
        font-size: 34px;
        line-height: 44px;
      }
    }
  }
  .joinUs-btn{
    width: 160px;
    line-height: 54px;
    height: 54px;
    font-size: 34px;
  }
}
</style>
